

const SocialMediaButton = ({ link, image, text, color, invertColor}) => {
    return (
        <a href={link} rel="nofollow noreferrer" target="_blank">
            <div className="social_button" style={{ background: color, color:(invertColor ? 'white' : 'black')}}>
                {text} <img src={image} alt="" height="25" style={{filter: 'invert('+(invertColor?1:0)+')'}} />
            </div>
        </a>
    )
}

export default SocialMediaButton
