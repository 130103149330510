import Game from './Game'
import Asset from './Asset'
import '../gameList.css'
import SortIcon from '../sort.png'
import ArrowIcon from '../arrowDown.png'

const GameList = ({name, gameList, isGame}) => {
    return (
        <div>
            <div className="Category">
                {name}
            </div>
            <div className="game_list">
                {/* <div className="game_sort">
                    <button onclick="myFunction()" className="game_sort_btn">
                        Newest
                        <img src={ArrowIcon} alt="" height="25px"/>
                    </button>
                    <div id="myDropdown" className="game_sort_content">
                        <a href="#home">Newest</a>
                        <a href="#about">Most pooular</a>
                    </div>
                </div> */}

                <div className="Games">
                
                {gameList.map((gameObj, index) => (
                        (isGame ? <Game key={index} game={gameObj} /> : <></>)
                ))}
            </div>
            </div>
        </div>
    )
}

export default GameList
