import Introduction from './components/Introduction'
import SocialLinks from './components/SocialLinks'
import GameList from './components/GameList'

import gameList from './itchGameList.json'

import { useState, useRef} from 'react'

function App() {

  const mainDiv = useRef(null);

  const [assetList] = useState([
    {
      id: 1,
      name: "Space Fighter",
      image: 'https://media.sketchfab.com/models/787437b9849e4c23b08e081d55fe374d/thumbnails/6ad5aa9b4eb4443c8c0b1499463f1ea3/d25b3ffa3c594a2eaecf7ddd3d5fe593.jpeg',
      link: 'https://sketchfab.com/3d-models/fighter-787437b9849e4c23b08e081d55fe374d'
  },
  {
    id: 1,
    name: "Candy house",
    image: 'https://media.sketchfab.com/models/fb97f8d420634027bc0e2c1ff4cd9bd5/thumbnails/5c7309ef33ca46b2b6da705602f58ba4/cc2ba41310ab4872a64ef7f34dacc360.jpeg',
    link: 'https://skfb.ly/onBM6'
},
  ])

  return (
    <div className="App" ref={mainDiv}>   
      <Introduction />  
      <SocialLinks/>
      <GameList name="Games" gameList={gameList} isGame={true} />
      <GameList name="Assets" gameList={assetList} isGame={true} /> 
    </div>
  );
}

export default App;
