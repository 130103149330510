import logo from '../PanjoTitle.svg'
import background from '../background.png'
import '../introduction.css'


const Introduction = () => {
    return (
        <div className="introduction_base" style={{backgroundImage:"url("+background+")"}}>
            <div>
                <img src={logo} height="100%" alt="" />
            </div>
        </div>
    )
}

export default Introduction
