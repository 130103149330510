const Game = ({game}) => {
    return (
        <a className="GameLink" href={game.link} rel="nofollow noreferrer" target="_blank">
            <div className="Game">
                <div className="game_thumb" style={{background:"url("+game.image+") 50%", backgroundSize:"auto 250px"}} title={game.name}></div>
                <h3 className="game_title">{game.name}</h3>
                <h4 className="game_description">{game.description}</h4>
            </div>
        </a>
    )
}

export default Game
