import twitter from '../twitter.png'
import youtube from '../youtube.png'
import itch from '../itch-io.png'
import email from '../email.png'
import github from '../github.png'

import '../socialLinks.css'

import SocialMediaButton from './SocialMediaButton'



const SocialLinks = () => {


    return (
        <div>
            <div className='no_sticky_box'>
                <div className="media_box">
                    <SocialMediaButton link="https://twitter.com/DasPanjo" image={twitter} text="Twitter" color="#00acee" invertColor={true}/>
                    <SocialMediaButton link="https://www.youtube.com/channel/UCqD2ITylI03uEqhMJvFO_xw" image={youtube} text="Youtube" color="#FF0000"invertColor={true}/>
                    <SocialMediaButton link="https://dasPanjo.itch.io/" image={itch} text="Itch.io" color="#fa5c5c"invertColor={true}/>
                    <SocialMediaButton link="mailto:dasPanjo@gmail.com" image={email} text="E-Mail" color="#eee" invertColor={false}/>
                    <SocialMediaButton link="https://github.com/dasPanjo" image={github} text="Github" color="#111" invertColor={true}/>
                </div>
            </div>
        </div>
    )
}


export default SocialLinks
